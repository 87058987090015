import { extend } from 'vee-validate';
import { required, email, alpha_spaces } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
});

extend('email', {
    ...email,
});

extend('alpha_spaces', {
    ...alpha_spaces,
});

extend('url', {
    validate(value) {
        if (value) {
            return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
        }

        return false;
    },
});

extend('intlphone', {
    validate: (value) => {
        if (value) {
            return contactIti.isValidNumber();
        }
        return false;
    },
});

extend('intlphonecareers', {
    validate: (value) => {
        if (value) {
            return contactItiCareers.isValidNumber();
        }
        return false;
    },
});
