// CV Upload
import Vue from 'vue';
import VueRouter from 'vue-router';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import './validations';
import intlTelInput from 'intl-tel-input';
import axios from 'axios';

const router = new VueRouter({
    mode: 'history',
});

Vue.use(VueRouter);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

new Vue({
    el: '#joinus',
    router,
    data: {
        fullName: '',
        email: '',
        phone: '',
        position: '',
        message: '',
        terms: false,

        countryCode: '',
        countryCodeLetters: '',
        country: '',

        privacy: {},
        formLoading: false,
    },
    methods: {
        openFileInput() {
            $('#candidate_cv').trigger('click');
        },
        getPrivacyPolicy() {
            const lang = document.documentElement.lang.substring(0, 2);

            $.getJSON(`https://studioapps.kwanko.com/api/legal/privacy/contact-form/${lang}`)
                .then((response) => {
                    this.privacy = response;
                });
        },
        setFileName() {
            $('#filename').text(($('#candidate_cv').val()).split(/fakepath\\/)[1]);
        },
        toBase64(file) {
            if (file == null) {
                return new Promise((resolve) => resolve());
            }
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        async formSubmit() {
            this.formLoading = true;
            let formData = {
                page: $('[name="candidate_page"]').val(),
                pageLang: $('[name="candidate_page_language"]').val(),
                fullName: this.fullName,
                email: this.email,
                companyName: this.companyName,
                phone: this.phone,
                position: this.position,
                message: this.message,
                terms: this.terms,
                country: this.country,
                country_code_letters: this.countryCodeLetters,
                country_code: this.countryCode,
                utm_source: this.$route.query.utm_source || '',
                utm_campaign: this.$route.query.utm_campaign || '',
                utm_medium: this.$route.query.utm_medium || '',
                utm_content: this.$route.query.utm_content || '',
                utm_term: this.$route.query.utm_term || '',
                pubid: this.$route.query.pubid || '',
            };

            const base64Cv = await this.toBase64($('#candidate_cv').prop('files')[0]);
            if (base64Cv != null) {
                formData = { ...formData, cv: base64Cv.split(',')[1] };
            }

            axios.post('/wp-content/themes/kwankotheme/assets/vendor/careers/submit.php', formData)
                .then(() => {
                    this.formLoading = false;
                    $('#joinus').modal('hide');
                    $('#thanks').modal('show');

                    document.querySelector('#joinus').reset();
                }).catch((err) => {
                    this.formLoading = false;
                    this.$refs.formHandler.setErrors(err.response.data);
                });
        },
    },
    mounted() {
        this.getPrivacyPolicy();

        const { lang } = document.documentElement;
        let lpCountry = lang.slice(-2).toUpperCase();
        if (lang === 'en-us') {
            lpCountry = 'GB';
        }

        window.contactItiCareers = intlTelInput(this.$refs.phone_input, {
            formatOnDisplay: false,
            nationalMode: false,
            separateDialCode: true,
            preferredCountries: [lpCountry],
            utilsScript: '/wp-content/themes/kwankotheme/assets/js/vendor/intl-tel-input/build/js/utils.js',
        });

        // Open modal event
        $('#joinus').on('show.bs.modal', () => {
            // Prefill inputs when opening modal
            this.countryCode = `+${contactItiCareers.getSelectedCountryData().dialCode}`;
            this.countryCodeLetters = contactItiCareers.getSelectedCountryData().iso2;
            this.country = contactItiCareers.getSelectedCountryData().name;
        });

        this.$refs.phone_input.addEventListener('countrychange', () => {
            this.countryCode = `+${contactItiCareers.getSelectedCountryData().dialCode}`;
            this.countryCodeLetters = contactItiCareers.getSelectedCountryData().iso2;
            this.country = contactItiCareers.getSelectedCountryData().name;
        });
    },
});
